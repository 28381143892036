.root {
	z-index: 1200;
	width: 500px;
	background-color: #ffffff;
	height: calc(100% - 57px);
	overflow: auto;
	overflow-x: hidden;
	position: fixed;
	padding: 13px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	left: 400px;
	box-shadow: 5px 0 5px rgba(0, 0, 0, 0.2);
}
.header {
	position: sticky;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
}
.title {
	flex: 1;
	font-size: 16px;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 0 16px 4px;
}
.back {
	color: inherit;
	display: flex;
	justify-content: center;
	align-items: center;
}
.delete {
	cursor: pointer;
}
.contents {
	margin: 24px 11px;
}
