@value colors: '../../../styles/colors.module.css';
@value typography: '../../../styles/typography.module.css';
@value white, gray-100, gray-200, gray-400, dark-blue, dark-blue-050, dark-blue-200, dark-blue-400, error-050, error, warning-050, warning, blue-700, blue-050 from colors;

.asset-container {
	display: flex;
	color: white;
	align-items: center;
	margin: 4px;
	margin-left: 39px;
	padding: 8px;
	border-radius: 4px;
	text-decoration: none;
	cursor: pointer;
}

.asset-container span {
	font-size: 14px;
}

.asset-container:hover {
	background-color: #2b2b2b;
}

.icon {
	margin-right: 12px;
}
