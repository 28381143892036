.chip {
	background-color: #f1eaff !important;
	font-size: 14px;
	margin: 0 8px 8px 0;
	cursor: inherit;
}
.chip-default {
	composes: chip;
}
.chip-purpley {
	composes: chip;
	font-weight: 600;
	color: #8342f7 !important;
}
svg.avatar {
	color: inherit !important;
	font-size: 20px;
	height: 22px;
	vertical-align: middle;
	margin-right: 0 !important;
}
.label {
	padding-left: 6px !important;
	padding-bottom: 2px;
}
.label:first-child {
	padding-left: 12px !important;
}
