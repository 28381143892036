@value colors: '../../../styles/colors.module.css';
@value gray-500, purpley from colors;

.root {
	height: 56px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	color: gray-500;
	font-size: 16px;
	border: dashed 2px gray-500;
}

.icon {
	color: purpley;
	margin-right: 8px;
}
