@value colors: '../../../styles/colors.module.css';
@value gray-700, purpley, purpley-050, gray-400, black from colors;

.type {
	margin-right: 8px;
	margin-bottom: 8px;
	text-transform: none !important;
	height: 32px;
}
.type:hover {
	background: purpley;
	color: white;
}
.type-active {
	composes: type;
	background: purpley;
	color: white;
}
.type-inactive {
	composes: type;
	background: #f1eaff;
	color: purpley;
}
.type-label {
	font-size: 14px;
	font-weight: 600;
}
.form-helper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.form-helper-space {
	composes: form-helper;
	margin-bottom: 40px;
}
.form-helper-small {
	composes: form-helper;
	height: 30px;
	overflow: hidden;
}
.form-helper-big {
	composes: form-helper;
	margin-bottom: 16px;
}
.form-helper-big-top {
	composes: form-helper-big;
	align-items: flex-start;
}
.form-helper-list {
	composes: form-helper;
	flex-direction: column;
	align-items: stretch;
	color: #ff4d4d;
	font-weight: 600;
}
.form-helper-list > label {
	justify-content: space-between;
	margin-left: 0;
	padding-left: 0;
}
.form-helper-list > label > span:last-child {
	margin-left: 0;
	padding-left: 0;
}
.form-control {
	flex: 1;
	align-self: flex-start;
}
.form-helper-footer {
	flex: 1 0;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 66px;
}
.form-control-title {
	color: gray-700;
	font-size: 14px;
	font-weight: 600;
	margin-top: 11px;
}
.switch-bar.switch-bar.switch-bar {
	background-color: #ff4d4d;
}
.switch-icon {
	color: #ff4d4d;
}
.choice {
	margin: 8px 0 16px;
}
.contents {
	display: grid;
	padding: 52px 0 16px;
	max-width: 720px;
	margin: 0 auto;
	grid-template:
		'parent-choices parent-choices .' auto
		'title title .' 70px
		'description description .' auto
		'choices choices choices' auto
		'options options options' auto
		'add-choice . .' auto / 1fr auto;
	grid-template-rows: auto;
	box-sizing: border-box;
	min-height: 100%;
	position: relative;
}
.base {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 500px;
	top: 0;
	width: 500px;
	z-index: 1300;
}
.question-title {
	grid-area: title;
}

.question-help-text {
	grid-area: description;
	padding: 16px 0;
	margin: 16px 0;
}

.question-image-caption {
	margin-top: 8px;
	margin-bottom: 16px;
}
.choices-list {
	grid-area: choices;
}

.options {
	margin: 0 0 16px 0;
	grid-area: options;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.choices-add-option {
	grid-area: add-choice;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 16px;
}

.persist-container {
	grid-area: persist;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	padding: 64px 0 6px;
}

.choices-add-option-button {
	cursor: pointer;
	color: purpley;
	font-size: 14px;
	font-weight: 600;
	background: initial;
	border: initial;
	padding: initial;
	font-family: inherit;
	padding: 0;
	margin: 0 8px 0 0;
}

.choices-add-option-button svg {
	margin: 0 4px 0 8px;
	vertical-align: middle;
}

@keyframes yellowflash-bg {
	from {
		background: purpley-050;
	}
	to {
		background: white;
	}
}

.choice.highlight {
	animation: yellowflash-bg 2s;
}

.choice {
	display: grid;
	grid-template:
		'icon answer delete' auto
		'. description .' auto
		'. report-text .' auto
		'. dependent .' auto
		'. arrows .' auto / 36px 1fr 36px;
	position: relative;
	border-radius: 4px;
	padding: 16px 16px 16px 16px;
	background: white;
}
.choice-delete {
	grid-area: delete;
	margin: 6px 0;
	justify-self: flex-end;
	cursor: pointer;
}
.choice-icon {
	grid-area: icon;
	margin: 6px 0;
}
.choice-answer {
	grid-area: answer;
}
.choice-ordering {
	grid-area: arrows;
	text-align: right;
}
.choice-description {
	grid-area: description;
	margin-top: 16px;
}
.choice-report-text {
	grid-area: report-text;
	margin-top: 16px;
}
.choice-report-select {
	width: 170px;
	margin: 0 16px;
}
.choice-root.property-description-root {
	font-size: 16px;
	line-height: 1.5;
}
.choice-answer-root {
	composes: choice-root;
	font-weight: 600;
}

.choice-dependent {
	grid-area: dependent;
	padding-top: 24px;
}

.property-name-root,
.question-root {
	font-weight: 600;
	font-size: 20px;
	line-height: 1.4;
}

.computed {
	margin-top: 40px;
	border-top: 1px solid gray-400;
}

.computed-title {
	font-size: 16px;
	font-weight: 600;
	margin-top: 20px;
}

.computed-toggle-space {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.computed-toggle {
	margin: 0 0 0 auto;
}

.select-button {
	border-radius: 400px;
	background-color: purpley-050;
	border: none;
	padding: 0 10px;
}

.select-button:hover {
	border: none;
}

.select-icon {
	margin-right: 8px;
}

.select-text {
	margin-top: 40px;
}

.mendix {
	display: flex;
	flex-direction: column;
	margin-top: 32px;
}

.mendix-text-field {
	width: 100%;
}

.mendix-button {
	align-self: center;
	text-transform: none !important;
}

.mendix-text-blok {
	margin-top: 24px;
	display: flex;
	flex-direction: column;
}

.mendix-text {
	align-self: center;
	color: purpley;
	font-size: 12px;
	font-weight: normal;
	margin: 0;
}

.mendix-text-updated {
	composes: mendix-text;
	font-size: 16px;
}

.chip-link {
	text-decoration: none;
}

.parent-choices {
	grid-area: parent-choices;
	padding-bottom: 28px;
}

.parent-choices-title {
	color: black;
	font-size: 14px;
	font-weight: 600;
	line-height: 1.43;
	margin: 0 0 8px;
}

.parent-choices-title-text {
	display: inline-block;
	margin: 8px 0;
}

.parent-choices-divider {
	font-size: 14px;
	color: gray-700;
	margin: 0 12px;
}

.parent-choices-divider:last {
	display: none;
}

.parent-choices-items {
	display: inline-block;
	align-items: center;
	margin: 0 8px 8px 0;
}

.parent-choices-chip {
	margin: 0;
}

.dependent-properties-link {
	color: black;
	font-size: 14px;
	text-decoration: none;
	border-bottom: 1px solid black;
}

.dependent-properties-text {
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 90px;
}

.dependent-properties-text-long {
	composes: dependent-properties-text;
	max-width: 135px;
}

.dependent-properties-chip-icon {
	display: flex;
	align-items: center;
	margin: 8px;
	font-size: 8px;
	color: white;
	width: 16px;
	height: 16px;
	border-radius: 400px;
	background-color: purpley;
}

.dependent-properties-chip-clear {
	width: 10px;
	height: 10px;
	margin: auto;
}

.select-property-icon {
	margin-right: 5px;
	color: inherit;
}

.select:hover {
	color: white;
	background-color: purpley;
	cursor: pointer;
}

.select:focus {
	background-color: purpley-050;
}

.connected:focus {
	background-color: purpley-050;
}

.done-container {
	display: flex;
	flex-direction: column;
	max-width: 720px;
	margin: 0 auto;
}

.done {
	text-decoration: none;
	margin: 0 auto;
}

.done-button {
	text-transform: none !important;
	width: 73px;
	height: 38px;
}

.button-text {
	font-size: 16px;
	line-height: 1.25;
}

.dependent-select {
	margin: 0 16px;
}

.dependent-select-text {
	font-size: 16px;
}

.cancel-icon:hover {
	color: #6928df;
}
