@value colors: '../styles/colors.module.css';
@value purpley, purpley-100, black, white, blue-700, gray-850 from colors;

.root {
	height: calc(100% - 48px);
	width: 400px;
	border-right: #cfd5e3 1px solid;
	padding-left: 13px;
	box-sizing: border-box;
	position: relative;
	overflow: auto;
	background-color: black;
}
.root-selecting {
	background-color: #5714cd;
}
.header-selecting {
	border: 4px solid #5714cd !important;
}
.property-header-editable.header-selecting.header-active {
	background-color: purpley;
}

.property-header-editable.header-selecting:hover {
	background-color: #6928df !important;
}

.asset-header-editable.header-selecting:hover {
	background-color: #6928df !important;
}

.list {
	padding-left: 0;
	margin: 0;
}
.header {
	height: 40px;
	display: grid;
	grid-template-areas: 'icon lock title order-icons insert';
	grid-template-columns: auto auto 1fr auto auto;
	border-radius: 8px;
	border: 4px solid black;
	align-items: center;
}
.header-insert {
	composes: header;
}
.header:hover .property-menu-icon,
.header:hover .insert-icon,
.header:hover .order-icons,
.category-header:hover .category-menu-button {
	color: inherit !important;
}
.header-hover:hover:not(.header-active) {
	background-color: gray-850;
}
.header-active {
	background-color: blue-700;
}
.property-header-readonly {
	composes: header-insert;
	margin-left: 52px;
	padding-left: 8px;
	color: white;
}
.property-header-editable {
	composes: property-header-readonly header-hover;
}
.asset-header-readonly {
	composes: header-insert;
	margin-left: 35px;
	color: white;
}
.asset-header-editable {
	composes: asset-header-readonly header-hover;
}
.category-header {
	composes: header;
	margin-left: 0px;
	color: white;
}
.item {
	list-style: none;
}
.link {
	grid-area: title;
	color: inherit;
	text-decoration: inherit;
	cursor: pointer;
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: 10px;
}
.readonly {
	cursor: auto;
}
.property-link {
	composes: link;
	font-size: 14px;
	font-weight: normal;
}
.property-link-readonly {
	composes: property-link readonly;
}
.asset-link {
	composes: link;
	font-size: 14px;
	font-weight: 600;
}
.asset-link-readonly {
	composes: asset-link readonly;
}
.category-link {
	composes: link;
	font-size: 16px;
	font-weight: 600;
	cursor: auto;
	display: flex !important;
	align-items: center;
}
.expand-icon {
	grid-area: icon;
	height: 24px;
	margin-right: 16px;
	transition: transform 0.1s;
	font-size: 24px !important;
}
.lock-icon {
	grid-area: lock;
	font-size: 14px !important;
	align-self: center;
	justify-content: center;
	position: relative;
	margin-right: 18px;
}
.expand-icon-container {
	grid-area: icon;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.expand-icon-container-hidden {
	composes: expand-icon-container;
	opacity: 0;
	pointer-events: none;
}
.topbar {
	display: flex;
	align-items: center;
	background-color: inherit;
	height: 54px;
	margin-left: -13px;
	padding-left: 24px;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1;
	border-bottom: 1px solid gray-850;
}

.topbar-selecting {
	border-color: purpley;
}

.topbar-title {
	flex: 1;
	color: white;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
}
.topbar-interaction {
	color: white;
	margin: 0 10px;
	cursor: pointer;
}

.topbar-interaction:last-child {
	margin-right: 16px;
}

.property-menu-icon {
	grid-area: insert;
	color: transparent;
	align-self: center;
	margin-right: 11px;
}
.insert-icon {
	grid-area: insert;
	color: transparent;
	align-self: center;
	margin-right: 11px;
	transform: rotate(-90deg);
}
.order-icon-up {
	margin-right: 0;
	color: inherit;
}
.order-icon-down {
	margin-right: 11px;
	color: inherit;
}
.order-icon-disabled {
	visibility: hidden;
}
.order-icons {
	grid-area: order-icons;
	align-self: center;
	color: transparent;
}
.property-menu-icon.property-menu-icon:hover,
.insert-icon.insert-icon:hover {
	color: purpley-100 !important;
	cursor: pointer;
}
.order-icon-up:hover,
.order-icon-down:hover {
	color: purpley-100;
	cursor: pointer;
}
.category-menu-button {
	color: transparent !important;
	padding: 0 !important;
	grid-area: insert;
	margin-right: 11px !important;
}
.category-menu-button.category-menu-button:hover {
	color: purpley-100 !important;
}
.delete-icon {
	margin-right: 10px;
}
.edit-icon {
	margin-right: 10px;
}
.select-button {
	background-color: white;
	margin-right: 16px;
	padding: 2px 0;
	text-transform: capitalize;
	border: none;
}
.select-button:hover {
	background-color: #d6c0fb;
	text-transform: capitalize;
	border: none;
}
.check-box {
	display: flex;
	grid-area: lock;
	align-items: center;
	justify-content: center;
	height: 32px;
}

.radio {
	display: flex;
	grid-area: lock;
	align-items: center;
	justify-content: center;
	height: 32px;
}

.category-container {
	display: flex;
	color: white;
	align-items: center;
	margin: 4px;
	margin-left: 8px;
	padding: 8px;
	border-radius: 4px;
	text-decoration: none;
	cursor: pointer;
}

.category-container span {
	font-size: 16px;
}

.category-container:hover {
	background-color: #2b2b2b;
}

.icon {
	font-size: 18px;
	margin-right: 12px;
}
