@value colors: '../styles/colors.module.css';
@value blue-700, success-800, error-500, error-050 from colors;

.content {
	margin-top: 64px;
	margin-left: 400px;
	min-height: calc(100% - 64px);
	height: calc(100% - 64px);
	position: relative;
	overflow: auto;
}
.sidebar {
	background-color: #202020;
	color: white;
	position: fixed;
	left: 0;
	top: 64px;
	height: calc(100% - 64px);
	z-index: 1;
}
.topbar {
	position: fixed;
	left: 0;
	top: 0;
	height: 64px;
	width: 100%;
	display: flex;
	align-items: stretch;
	z-index: 1;
}

.snackbar {
	min-width: 528px;
	height: 48px;
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	padding: 0 16px;
}

.snackbar-saving {
	composes: snackbar;
	background-color: blue-700;
}

.snackbar-succes {
	composes: snackbar;
	background-color: success-800;
}

.snackbar-error {
	composes: snackbar;
	background-color: error-500;
}

.snackbar-asset-error {
	composes: snackbar-error;
	min-width: 650px;
}

.snackbar-message {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.button {
	background-color: white;
	color: error-500;
	font-size: 14px;
	text-transform: capitalize;
}

.button:hover {
	background-color: error-050;
}

.icon-color {
	color: white;
}
