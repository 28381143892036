@value colors: '../../../styles/colors.module.css';
@value dark-blue from colors;

.FormControlLabel {
	padding: 2px 4px;
	display: flex;
	user-select: none;
}

.label {
	color: inherit;
	font-weight: inherit;
	margin-left: 8px !important;
	font-size: 1em !important;
}
