@value colors: '../../styles/colors.module.css';
@value purpley-100 from colors;

.list-item-root .context-menu-button {
	visibility: hidden;
}

.list-item-root:hover .context-menu-button {
	visibility: visible;
}

.context-menu-button:hover {
	cursor: pointer;
	color: purpley-100;
}

.list-item {
	padding-left: 24px !important;
}
