@value colors: '../../../styles/colors.module.css';
@value dark-blue, blue-700, success, warning, error from colors;

.button {
	color: dark-blue !important;
	padding: 8px;
}

.primary.radioChecked,
.primary.radioChecked ~ span {
	color: blue-700;
}

.orange.radioChecked,
.orange.radioChecked ~ span {
	color: warning;
}

.red.radioChecked,
.red.radioChecked ~ span {
	color: error;
}

.green.radioChecked,
.green.radioChecked ~ span {
	color: success;
}
