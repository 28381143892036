@value colors: '../../../styles/colors.module.css';
@value dark-blue-200, dark-blue-400, dark-blue, error-600 from colors;

label.root,
.root label.error {
	color: #979797;
}

label.shrink {
	color: #979797;
}

label.focused,
.root:hover .shrink,
.root input {
	color: #202020;
}

.root input::placeholder,
.root textarea::placeholder {
	color: #979797;
	opacity: 1;
	transition: none;
}

.root.root fieldset {
	border-color: dark-blue-200;
}

label.error.shrink,
p.error {
	color: error-600;
}

.root .textfield-error.textfield-error fieldset {
	border-color: error-600;
}
