@value colors: '../../styles/colors.module.css';
@value gray-700 from colors;

.form {
	display: flex;
	flex-direction: column;
	flex: 1;
}
.header {
	display: flex;
}
.title {
	flex: 1;
	margin: 0 16px;
	font-size: 16px;
	font-weight: 600;
}
.form-helper {
	display: flex;
	align-items: center;
}
.form-helper-small {
	composes: form-helper;
	height: 30px;
	overflow: hidden;
}
.form-helper-big {
	composes: form-helper;
	height: 81px;
}
.form-control {
	flex: 1;
	align-self: flex-start;
}
.form-helper-footer {
	flex: 1 0;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 66px;
}
.form-control-title {
	color: gray-700;
	font-size: 14px;
	font-weight: 600;
	margin-top: 24px;
}
.back {
	color: inherit;
}
.delete {
	cursor: pointer;
}
.chip-link {
	text-decoration: none;
}
.asset-name-root {
	font-weight: 600;
	font-size: 20px;
	line-height: 1.4;
}
.contents {
	display: flex;
	padding: 52px 24px 78px;
	max-width: 720px;
	margin: 0 auto;
	box-sizing: border-box;
	min-height: 100%;
	position: relative;
}
.done {
	text-decoration: none;
	margin: 60px auto 0;
}
