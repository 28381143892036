.content-container {
	height: 100%;
	left: 0;
	right: 0;
	position: absolute;
	display: flex;
	background: #fafafa;
}
.content-container.has-property {
	left: 500px;
}
.content-textarea,
.content-highlights {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	border-radius: 0;
	resize: none;
	overflow: auto;
	white-space: pre-wrap;
	word-wrap: break-word;
	font-family: Barlow;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.63;
	letter-spacing: normal;
	border: none;
	background-color: transparent;
	padding: 0 96px;
	box-sizing: border-box;
}
.content-highlights {
	color: #2a3448;
	position: static;
	counter-reset: line;
}
.content-textarea {
	color: transparent;
	caret-color: #2a3448;
}
.content-highlights mark {
	background-color: transparent;
}
.mark-asset {
	color: #1c7cde;
}
.mark-property {
	color: #8342f7;
}
.mark-error {
	color: #ff1a1a;
}
.line {
	position: relative;
}
.line-error {
	composes: line;
}
.line-error-icon {
	top: 3px;
	color: red;
	z-index: 2;
	font-size: 20px !important;
}
.error-tooltip {
	background: #fde8ea;
	color: #ff1a1a;
	font-size: 12px;
	font-weight: 600;
	padding: 8px 9px 8px 16px;
	opacity: 1 !important;
	transform: none !important;
}
.error-popper {
	opacity: 1;
}
.errors {
	height: calc(100vh - 150px);
	position: absolute;
	z-index: 2;
	top: 40px;
	padding-top: 19px;
	left: calc(12.78vw - 80px);
	overflow: hidden;
}
.error-container {
	display: block;
}
.error-container:first-child {
	margin-top: 0 !important;
}
